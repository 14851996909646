<template>
  <div class="input-group subnet-fixed-popper" v-if="asInput" @keyup.esc.stop="form.cancel"
       @keyup.enter.stop="validationBeforeModal" ref="group">
    <label for="subnet-input" class="col-sm-2 col-form-label w-auto pe-2" v-if="showLabel">Сеть:</label>
    <popper placement="top" class="warning w-90" v-if="asInput" :arrow="true" :show="errorPopper.show">
      <input type="text" class="form-control" :class="{'is-invalid': !isValid}" id="subnet-input" @input="isValid = true;
      errorPopper.show = false;" v-model="proxy.subnet" ref="input">
      <!-- div class="form-check" v-if="userCanSetLot && showSetLot">
        <label class="form-check-label text-start" for="setlot">Выставить на продажу</label>
        <input class="form-check-input" type="checkbox" v-model="setLotModal.value" @change="changeSetLot" id="setlot">
      </div -->
      <template #content>
        <span v-html="errorPopper.content"></span>
      </template>
    </popper>
  </div>

  <div v-else class="d-flex subnet-fixed-popper subnet-string" :class="{'align-items-center': !showLabel}" ref="subnet">
    <template v-if="showLabel">Сеть:&nbsp;</template>

    <popper v-if="showTransferPopper"
            placement="left"
            class="warning transfer-popper"
            :arrow="true"
            :show="!disableTransferPopper">
      <subnet-string :subnet="subnet"
                     :showError="true"
                     :user-can-change="userCanChange"
                     @showForm="showForm" />
      <template #content>
          <transfers v-if="transfersWarning"
                     :subnet-type="type"
                     :transfers="transfers"
                     @update-fields="updateFields"
                     @showModal="disableTransferPopper = true"
                     @hideModal="disableTransferPopper = false"
          />
      </template>
    </popper>

    <popper v-else-if="criticalChangeWarning" class="warning" placement="top" :hover="true" :interactive="false" :arrow="true">
      <subnet-string :subnet="subnet"
                     :showError="true"
                     :user-can-change="userCanChange"
                     @showForm="showForm" />
      <template #content>
        <critical-change :critical-change="this.criticalChange" />
      </template>
    </popper>

    <subnet-string v-else
                   :subnet="subnet"
                   :user-can-change="userCanChange"
                   @showForm="showForm" />

    <rir v-if="rir"
         :rir="rir"
    />

    <type v-else-if="subnet"
          :type="type"
          :user-can-change="userCanChange"
          @update="(val) => { proxy.type = val; form.save(); }"
          ref="type-input" />

    <current-operations
        v-if="showCurrentOperations"
        :current-operations="currentOperations"
    ></current-operations>

    <bids v-if="showBids && showCurrentBid && setlot && ticket != null"
          :subnet="subnet"
          :ticket="ticket" />

    <span class="pull-right ps-1" v-if="showAdd">
      <i class="fa fa-plus-circle form-add" style="color:#6262f5;cursor:pointer;" @click="addSubnet"></i>
    </span>

    <add-to-auction   v-if="!withoutAddToAuction &&  showAuctions && !setlot" >
    </add-to-auction>
  </div>

  <teleport to="body">
    <whois-modal
        v-if="whoisModal.show"
        :subnet="whoisModal.subnet"
        :user-can-set-lot="userCanSetLot"
        @cancel="whoisModalCancel"
        @confirm="save"
        class="subnet-whois-modal"
    />
  </teleport>
</template>

<script>
import {Form} from "./Form";
import EditableSelect from "../../../../../Editable/EditableSelect";
import {CrmApi} from "../../../../../../library/CrmApi";
import WhoisModal from "./Subnet/WhoisModal";
import StringTrim from "../../../../../string/StringTrim";
import AddEdit from "../../../../../offerHistory/Modal/AddEdit";
import SubnetString from "./Subnet/SubnetString";
import Type from "./Subnet/Type";
import Transfers from "./Subnet/Transfers";
import CriticalChange from "./Subnet/CriticalChange";
import SetLotModal from "./Subnet/SetLotModal.vue";
import Bids from "./Subnet/Bids.vue";
import Rir from "./Subnet/Rir";

import AddToAuction from "./Subnet/AddToAuction.vue";
import {AuctionSetup} from "./AuctionSetup";
import CurrentOperations from "./Subnet/CurrentOperations";


export default
{
  name: "Subnet",
  components: {
    AddToAuction,
    Bids,
    AddEdit,
    StringTrim,
    EditableSelect,
    WhoisModal,
    SubnetString,
    Type,
    Transfers,
    CriticalChange,
    SetLotModal,
    Rir,
    CurrentOperations,
  },
  emits: ['update', 'updateFields', 'addSubnet', 'close'],

  props: {
    withoutAddToAuction:{
      type:Boolean,
      default:false,
    },
    showBids:{
      type:Boolean,
      default:true,
    },
    showAuctions:{
      type:Boolean,
      default:true,
    },
    subnet: {
      default: null
    },
    prefix: {
      default: null
    },
    type: {
      default: null
    },
    rir: {
      default: null
    },
    transferPopper: {
      type: Boolean,
      default: false,
    },
    transfers: {
      default: [],
    },
    criticalChange: {},
    index: {},
    existsSubnets: {
      type: Array,
      default: [],
    },
    isNotSaved: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showTable: {
      type: Boolean,
      default: false
    },
    lotAllSubnets: {
      default: null,
    },

    userCanChange: {
      type: Boolean,
      default: false
    },

    userCanSetLot: {
      type: Boolean,
      default: false
    },
    lot: {
      default: null,
    },
    offer: Object,

    currentOperations: {
      default: [],
    },
  },

  data: function() {
    return {
      asInput: false,
      isValid: true,

      errorPopper: {
        show: false,
        content: null,
      },

      proxy: {},
      afterSave: this._afterSave,
      beforeSave: this._beforeSave,
      failSave: this._failSave,
      beforeCancel: this.cancel,
      ignoreClickElements: ['.subnet-whois-modal', '.subnet-setlot-modal'],
      ignoreCLickClasses: ['close', 'save'],

      form: new Form(this),
      fields: ['subnet', 'type'],
      converter: require('ip-subnet-calculator'),
      ipCIDR: require('ip-cidr'),

      whoisModal: {
        show: false,
        subnet: null,
      },
      disableTransferPopper: false,

      deleteWhenCancel: false,

      setLotAllSubnets: false,
      setLotModal: {
        show: false,
        value: false,
      },
    }
  },
  setup(props){
    return  AuctionSetup(props);
  },
  computed: {
    showCurrentBid() {
      return this.index === 0;
    },
    setlot() {
      let result;
      if (typeof this.offer === 'object' && this.offer !== null) {
        result = Boolean(this.offer.assigned_product?.fields?.lot?.date);
      } else {
        result = this.isHasLot;
      }
      return result;
    },
    showTransferPopper() {
      return this.transferPopper && this.transfersWarning && this.userCanChange;
    },

    isSubnetSet: function() {
      return (typeof this.subnet === 'string' && this.subnet !== '');
    },

    subnetString: function() {
      return this.isSubnetSet ? this.subnet : '—';
    },

    inputValue: function() {
      return this.subnetString === '—' ? '' : this.subnetString;
    },

    transfersWarning() {
      return this.transfers.length > 0;
    },

    popperCssPosition() {
      return this.showTable ? 'absolute' : 'fixed';
    },

    criticalChangeWarning: function() {
      return (typeof this.criticalChange === 'object' && typeof this.criticalChange.date !== 'undefined')
    },

    criticalChangeMessage() {
      let content = null;
      let change = this.criticalChange;

      if(typeof change.type === 'string') {
        let date = moment(change.date).format('DD.MM.Y');

        switch(change.type) {
          case 'created':
            content = `${date} сеть была создана`;
            break;
          case 'change':
            content = `${date} было изменено поле <i>${change.attr}</i> с <i>${change.from}</i> на <i>${change.to}</i>`;
            break;
        }
      }

      return content;
    },
    role() {
      return this.userCanChange ? 'button' : 'none';
    },
    showSetLot()  {
      return !this.isNotSaved && this.subnet === this.proxy.subnet;
    },

    showCurrentOperations() {
      return typeof this.currentOperations === 'object' &&
             Array.isArray(this.currentOperations) &&
             this.currentOperations.length > 0;
    },
  },

  created() {
    this.setSetLotValue();
  },

  methods: {
    setSetLotValue(value = null) {
      if (value === null) {
        this.setLotModal.value = typeof this.lot?.date !== 'undefined';
      } else {
        this.setLotModal.value = value;
      }
    },
    changeSetLot() {
      this.setLotModal.show = true;
      this.$store.commit('setActiveWindow', 'setLotModal');
    },
    setLotModalCancel: function() {
      this.setLotModal.show = false;
      this.$store.commit('removeActiveWindow', 'setLotModal');
      this.setLotModal.value = !this.setLotModal.value;
    },
    addSubnet() {
      if(this.subnet === null || this.subnet === '') {
        this.showForm();
      } else {
        this.$emit('addSubnet', true);
      }
    },

    whoisModalCancel: function() {
      this.hideWhoisModal();
      this.$refs.input.focus();
    },

    showForm: function() {
      if (this.userCanChange) {
        this.isValid = true;
        this.form.showForm();
        this.proxy.subnet = this.inputValue;
      }
    },

    _beforeSave: function(updated) {
      updated.index = this.index;
      return updated;
    },

    _afterSave: function(wasSaved = true) {
      if(wasSaved) {
        this.deleteWhenCancel = false;
      }

      this.$nextTick(() => this.$emit('close'));
    },

    _failSave: function(error) {

      this.deleteWhenCancel = true;
      let handled = false;
      if(typeof error?.response?.data !== 'undefined') {
        let data = error.response.data;
        if(typeof data.type !== 'undefined') {
          switch(data.type) {
            case 'subnet_already_in_use':
              if(typeof data.ticket_id !== 'undefined') {
                this.isValid = false;
                this.errorPopper.content = 'Сеть уже используется в <a href="/tickets/' + data.ticket_id + '/" target="_blank">другом запросе</a>';
                this.errorPopper.show = true;
                handled = true;

                this.form.showForm(false);
              }
              break;
          }
        }
      }

      if(!handled) {
        if (typeof error?.response?.data?.html !== 'undefined' && error.response.data.html === true) {
          this.$store.commit('htmlErrorPush', error.response.data.message);

          if (error.response.data.type == 'client_not_approved'){
              this.setLotModal.value  = false;
              this.asInput = false;
          }

        } else {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }
      }
    },

    validationBeforeModal: function() {
      let subnet = null;
      let prefix = null;

      if(this.subnet === this.proxy.subnet) {
        this.form.cancel();
        return;
      }

      if(this.errorPopper.show) {
        this.isValid = false;
      } else if(this.proxy.subnet.indexOf('/') !== -1) {

        [subnet, prefix] = this.proxy.subnet.split('/');
        this.isValid = (this.ipCIDR.isValidAddress(subnet) && prefix > -1 && prefix < 33);
        if(this.isValid) {
          this.isValid = (new this.ipCIDR(subnet + '/' + prefix).addressStart.address === subnet);
        }

        subnet = subnet + '/' + prefix;
      } else if(this.proxy.subnet.indexOf('-') !== -1) {

        let range = this.proxy.subnet.split('-').map(ip => ip.trim());
        let converter = this.converter.calculate(range[0], range[1]);

        if(Array.isArray(converter) && converter.length === 1) {
          prefix = converter[0].prefixSize;
          subnet = range[0] + '/' + prefix;
        } else {
          subnet = this.proxy.subnet;
          prefix = null;
        }

      } else if(this.ipCIDR.isValidAddress(this.proxy.subnet)) {

        subnet = this.proxy.subnet.replace(/\.\d{0,3}$/, '.0') + '/24';
        prefix = 24;
      } else if(this.proxy.subnet !== '') {
        this.isValid = false;
      }

      if(this.existsSubnets.indexOf(subnet) > -1) {
        this.isValid = false;
      }

      if(this.isValid) {
        this.proxy.subnet = subnet;
        this.proxy.prefix = prefix;
        this.proxy.type = null;

        if(this.proxy.subnet !== '' && this.proxy.subnet !== null) {
          // если subnet не пустой, показываем модальное окно с подтверждением добавления
          this.showWhoisModal(this.proxy.subnet);
          this.$refs.input.blur();
        } else {
          this.save();
        }
      }
    },

    save: function(added = {}) {
      if(this.isValid) {
        let fields = {
          subnet: this.proxy.subnet,
          prefix: this.proxy.prefix ?? this.prefix,
          type: this.proxy.type,
        };
        if (typeof added === 'object' && added !== null && Object.keys(added).length > 0) {
          Object.assign(fields, added);
        }
        this.hideWhoisModal();

        this.form.save(fields);
      }
    },

    cancel: function() {
      if(this.errorPopper.show) {
        return false;
      }

      if(this.subnet === null || this.subnet === '' ||
         this.isNotSaved || this.deleteWhenCancel) {

        this.form.clear();
        this.isValid = true;
        this.errorPopper.show = false;
        this.$emit('close');
      }
    },

    updateFields(data) {
      if(typeof data.fields !== 'undefined') {
        this.$emit('updateFields', data.fields);
      }
    },

    showWhoisModal(subnet) {
      this.whoisModal.subnet = subnet;
      this.whoisModal.show = true;
      this.$store.commit('setActiveWindow', 'whoisModal');
    },

    hideWhoisModal() {
      this.whoisModal.subnet = null;
      this.whoisModal.show = false;
      this.$store.commit('removeActiveWindow', 'whoisModal');
    }
  },

  watch: {
    showTransferPopper(to) {
      if(to) {
        this.$nextTick(() => {
          if(this.transferPopper) {
            $(this.$refs['subnet']).find('div.transfer-popper').on('mouseenter', (e) => {
              let popper = $(e.target).closest('div.transfer-popper');
              if(!popper.hasClass('transfer-popper-active')) {
                $('div.transfer-popper').removeClass('transfer-popper-active');
                popper.addClass('transfer-popper-active');
              }
            });
          }
        });
      }
    },

    lot: {
      handler() {
        this.setSetLotValue();
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

.subnet-string:hover :deep(.add-to-auction){
  display: block;
}
.subnet-fixed-popper:deep(.popper) {
  position: v-bind(popperCssPosition) !important;
  z-index: 1040;
}

.warning {
  display: inline-block !important;
  --popper-theme-background-color: #f5a35b;
  --popper-theme-background-color-hover: #f5a35b;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 5px;
  --popper-theme-padding: 0.7em;
  --popper-theme-box-shadow: 0 2px 15px -2px rgba(0, 0, 0, 0.25);
}
.w-90{
  width: 90%;
}

.transfer-popper {
  border: none !important;
  margin: unset !important;
}
</style>